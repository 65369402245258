import { Component } from '@angular/core';

@Component({
  selector: 'app-cle-maintenance',
  templateUrl: './cle-maintenance.component.html',
  styleUrls: ['./cle-maintenance.component.scss']
})
export class CleMaintenanceComponent {

}
