import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventPayload, EventType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject, filter, Observable, Subject, takeUntil } from 'rxjs';
import { AuthUserModel } from 'src/app/_shared/models/auth-user.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit, OnDestroy {

  profile: any;

  private _token = new BehaviorSubject<string>(null);

  private _isAuthenticated = new BehaviorSubject<boolean>(null);

  private readonly _destroying$ = new Subject<void>();

  private _holdAttemptedUrl: string;

  private _authUser: AuthUserModel;

  private _accountInfo = new BehaviorSubject<AccountInfo>(null);

  set attemptedUrl(val: string){
    //console.log('set url=' + val);
    this._holdAttemptedUrl = val;
  }

  get attemptedUrl(): string {
    return this._holdAttemptedUrl;
  }



  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService
  ) {

  }

  ngOnInit(): void {

    var accessToken = JSON.parse(sessionStorage.getItem('accessToken'));
    if (accessToken) {
      this._token.next(accessToken);
    }

    var accountInfo = JSON.parse(sessionStorage.getItem('accountInfo'));
    if (accountInfo) {
      this._accountInfo.next(accountInfo);
    }

    //console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa AuthService: AuthenticationResult');


    this.msalService.acquireTokenSilent({ scopes: ['api://5e19a916-9eb9-427a-8a85-5880605739b9/CLE.SponsorPortal.Access'] })
      .subscribe((response: AuthenticationResult) => {
        //console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa AuthService: AuthenticationResult', response);
      });


    this.msalService.handleRedirectObservable().subscribe( (redirectResponse) => {
      //console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa AuthService: redirectResponse', redirectResponse);
      if(redirectResponse != null) {
        // Acquire token silent success
        let accessToken = redirectResponse.accessToken;
        this._token.next(accessToken);
        // Call your API with token
        //console.log("We got the token! hahaha: " + accessToken);
      }
    });

    this.broadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      takeUntil(this._destroying$)
    )
    .subscribe((result: EventMessage) => {
      // Do something with event payload here
      //console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa AuthService: acquire token success', result.payload);
      var stuff = result.payload as EventPayload;
      this._token.next( this.msalService.instance.getActiveAccount()?.idToken);

      this._isAuthenticated.next(this.msalService.instance.getAllAccounts().length > 0);

    });

    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType == EventType.ACCOUNT_ADDED || msg.eventType == EventType.ACCOUNT_REMOVED))
      .subscribe((result: EventMessage) => {

        this._token.next( this.msalService.instance.getActiveAccount()?.idToken);

        this._isAuthenticated.next(this.msalService.instance.getAllAccounts().length > 0);

      });

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public getAccessToken(): Observable<string>
  {
    return this._token.asObservable();
  }

  public setAccessToken(token: string)
  {
    sessionStorage.setItem('accessToken', JSON.stringify(token));
    this._token.next(token);
  }

  public setAccountInfo(accountInfo: AccountInfo)
  {
    this._accountInfo.next(accountInfo);
    sessionStorage.setItem('accountInfo', JSON.stringify(accountInfo));
  }

  public getAccountInfo(): Observable<AccountInfo>
  {
    return this._accountInfo.asObservable();
  }

  isAuthenticated() {
    return this._isAuthenticated.asObservable();
  }

  public setIsAuthenticated(isAuthenticated: boolean)
  {
    this._isAuthenticated.next(isAuthenticated);
  }

  getUserDetails(){
    const profileData = this.msalService.instance.getAllAccounts()[0];
    console.log('profileData', profileData);
    // this._authUser = { firstName: profileData.idTokenClaims['given_name'] as string,
    //   lastName: profileData.idTokenClaims['family_name'] as string,
    //   email: profileData.idTokenClaims['email'] as string
    // };
    return profileData.username;
  }

  microsoftLogin() {
    console.log('this.msalGuardConfig.authRequest' , this.msalGuardConfig.authRequest);

      if (this.msalGuardConfig.authRequest){
      this.msalService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);

        });
      } else {
        this.msalService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);

      });
    }

  }

  microsoftLogout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: location.origin
    });
  }
}
