import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { AppMaintGuard } from './_core/guards/app-maint.guard';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AppMaintFailGuard } from './_core/guards/app-maint-fail.guard';
import { CleMaintenanceComponent } from './maintenance/cle-maintenance/cle-maintenance.component';

const routes: Routes = [
  {
    path: 'cle', loadChildren: () => import('./CLE/cle.module').then(m => m.CleModule),
    canLoad: [AppMaintGuard],
    //canActivate: [MsalGuard],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'maintenancecle',
    component: CleMaintenanceComponent
  },
  {
    path: 'misc', loadChildren: () => import('./Misc/misc.module').then(m => m.MiscModule),
    canLoad: [AppMaintGuard],
  },
  {
    path: 'ogc', loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
    canLoad: [AppMaintGuard],
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: '',
    pathMatch: "full",
    redirectTo: 'cle'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
