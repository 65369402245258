import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface NotifyDialogData {
  caption: string;
}

@Component({
  selector: 'app-confirm-continue',
  templateUrl: './confirm-continue.component.html',
  styleUrls: ['./confirm-continue.component.scss']
})
export class ConfirmContinueComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: NotifyDialogData,
  ) { }

  ngOnInit(): void {
  }

  getCaption() {
    return this.data.caption;
  }

  continue() {
    this.dialogRef.close("continue");
  }

  cancel() {
    this.dialogRef.close("cancel");
  }

}
