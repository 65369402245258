import { Component, Inject, OnInit } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './_core/services/auth.service';
import { licenseKey } from '../devextreme-license';
import config from 'devextreme/core/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {
  title = 'spa';

  isAuth: any;

  isIframe = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appAuthService: AuthService,
    private msalService: MsalService,
  ) {
    //this.checkLogin();
  }

  ngOnInit() {

    config({ licenseKey });

    this.router.events
    .pipe(
      filter((e: any): e is RouterEvent => e instanceof RouterEvent)
    ).subscribe((e: RouterEvent) => {
      if (e.url.includes('auth-return')) {
        return;
      }
      this.appAuthService.attemptedUrl = e.url;
    });

    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    const isAuth =this.appAuthService.isAuthenticated();

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      takeUntil(this._destroying$)
    )
    .subscribe((result: EventMessage) => {
      const payload = result.payload as AuthenticationResult;
      console.log('-------------------------------------- ACQUIRE_TOKEN_SUCCESS', payload);
      this.appAuthService.setAccountInfo( payload.account);
      this.appAuthService.setAccessToken(payload.accessToken);
      this.appAuthService.setIsAuthenticated(true);
    });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        console.log('-------------------------------------- InteractionStatus.None');
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log('-------------------------------------- logout');
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });


    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log('-------------------------------------- LOGIN_SUCCESS');
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

  }

  setLoginDisplay() {
    //this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();


    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      console.log('-------------------------------------- checkAndSetActiveAccount', accounts);
      // add your code for handling multiple accounts here
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  // isAuthorized() {
  //   return this.authService.isAuthenticated;
  // }

  // private async checkLogin() {
  // //console.log('app.config checkLogin');
  // //const si = this.authService.checkSignedIn();
  // //const signin = await this.authService.signInSilent(null);
  //   const check = await this.authService.checkSignedIn().then(value => {
  //     return;
  //   });

  // }


  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}


