import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './_shared/shared.module';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { HomeComponent } from './home/home.component';
import { LoadingInterceptor } from './_core/interceptors/loading.interceptor';
import devextremeAjax from 'devextreme/core/utils/ajax';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';

import {
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MsalService,
  MsalBroadcastService,
  MsalGuard,
  MsalRedirectComponent,
  MsalInterceptor,
  MsalModule,
  MsalInterceptorConfiguration,
  MSAL_INTERCEPTOR_CONFIG,
  ProtectedResourceScopes,
} from '@azure/msal-angular';

import { msalConfig, loginRequest } from './auth-config';
import { environment } from 'src/environments/environment';
import { sendRequestFactory } from 'src/ng-http-client-helper';
import { ForwardLinkComponent } from './forward-link/forward-link.component';
import { CleMaintenanceComponent } from './maintenance/cle-maintenance/cle-maintenance.component';


export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    //console.log('url=' + url);

    const firstPart = url.substring(0, url.indexOf('?')) || url;
    if (firstPart == url) {
      return super.parse(url.toLowerCase());
    }

    const lastPart = url.substring(url.indexOf('?'), url.length);
    // console.log('first part=' + firstPart);
    // console.log('last part=' + lastPart);

    const newURL = firstPart.toLowerCase() + lastPart;
    //console.log('new url=' + newURL);
      // Optional Step: Do some stuff with the url if needed.

      // If you lower it in the optional step
      // you don't need to use "toLowerCase"
      // when you pass it down to the next function
      return super.parse(newURL);
  }
}


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MsalGuardConfigurationFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(environment.AuthSettings.cleAPI.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...environment.AuthSettings.cleAPI.scopes.read]
    },
    {
      httpMethod: 'POST',
      scopes: [...environment.AuthSettings.newCourseAPI.scopes.write]
    },
    {
      httpMethod: 'PUT',
      scopes: [...environment.AuthSettings.newCourseAPI.scopes.write]
    },
    {
      httpMethod: 'DELETE',
      scopes: [...environment.AuthSettings.newCourseAPI.scopes.write]
    }
  ]);

  // protectedResourceMap.set(environment.AuthSettings.newCourseAPI.endpoint, [
  //   {
  //       httpMethod: 'GET',
  //       scopes: [...environment.AuthSettings.newCourseAPI.scopes.read]
  //   },
  //   {
  //       httpMethod: 'POST',
  //       scopes: [...environment.AuthSettings.newCourseAPI.scopes.write]
  //   },
  //   {
  //       httpMethod: 'PUT',
  //       scopes: [...environment.AuthSettings.newCourseAPI.scopes.write]
  //   },
  //   {
  //       httpMethod: 'DELETE',
  //       scopes: [...environment.AuthSettings.newCourseAPI.scopes.write]
  //   }
  // ]);

  // protectedResourceMap.set(environment.AuthSettings.newCoursesAPI.endpoint, [
  //   {
  //       httpMethod: 'POST',
  //       scopes: [...environment.AuthSettings.newCourseAPI.scopes.write]
  //   },
  //]);

  return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MaintenanceComponent,
    HomeComponent,
    ForwardLinkComponent,
    CleMaintenanceComponent
  ],
  imports: [
    BrowserModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgbModule,
    SharedModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoadingInterceptor,
    //   multi: true
    // },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigurationFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {

  constructor(httpClient: HttpClient) {
    devextremeAjax.inject({ sendRequest: sendRequestFactory(httpClient) });
  }

 }
